import React from 'react'
import styled from 'styled-components'
import ErrorMessage from './ErrorMessage'
import theme from '../../styling/theme'

interface InputProps {
  value: string
  onChange: any
  label: string
  isValidated: boolean
  noMarginBottom?: boolean
  noPaddingTop?: boolean
  placeholder?: string
  width?: number
  onBlur?: any
  hasError?: boolean
  errorTitle?: string
  id?: string
  color?: string
}

interface InputElementProps {
  isValidated: boolean
  hasError: boolean
}

interface IInputContainerProps {
  noMarginBottom?: boolean
  noPaddingTop?: boolean
  color?: string
}

const Input = ({
  value,
  onChange,
  label,
  isValidated,
  noMarginBottom,
  noPaddingTop,
  placeholder,
  width,
  onBlur,
  hasError = false,
  errorTitle = '',
  id,
  color,
}: InputProps) => {
  return (
    <InputContainer
      noPaddingTop={noPaddingTop}
      noMarginBottom={noMarginBottom}
      color={color}
    >
      <Anchor id={id} />
      <label htmlFor={id}>{label}</label>
      <SmallContainer>
        <InputElement
          value={value}
          onChange={e => onChange(e.target.value)}
          isValidated={isValidated}
          placeholder={placeholder}
          width={width}
          onBlur={onBlur}
          hasError={hasError}
          spellCheck="false"
          autoComplete="false"
          id={id}
        />
      </SmallContainer>
      {hasError && <ErrorMessage title={errorTitle} />}
    </InputContainer>
  )
}

const SmallContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const CardGuide = styled.div`
  display: flex;
  align-items: center;

  & img {
    height: 46px;
    margin-left: 20px;
    @media only screen and (max-width: 850px) {
      margin-left: 10px;
    }
  }

  & p {
    font-size: 14px;
    line-height: 14px;
    font-weight: 300;
    margin-left: 15px;
    color: ${theme.text.second};
    line-height: 20px;
    width: 116px;

    @media only screen and (max-width: 850px) {
      margin-left: 10px;
      width: 100%;
      font-size: 13px;
      line-height: 23px;
    }
  }
`

const Anchor = styled.div`
  position: absolute;
  top: -70px;
  left: 0;
`

const InputContainer = styled.div<IInputContainerProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 2.8rem;
  ${props => props.noMarginBottom && `margin-bottom: 0`};
  position: relative;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 500;
  & label {
    color: ${props => (props.color ? props.color : '#000000')};
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 23px;
    font-weight: 300;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
`

const InputElement = styled.input<InputElementProps>`
  height: 6rem;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 16px;
  line-height: 1.9rem;
  font-weight: bold;
  border-radius: 0;
  box-shadow: inset 0 1.5px 2px 0 rgba(0, 0, 0, 0.25);

  transition: background-color 200ms ease, border-color 200ms ease,
    box-shadow 200ms ease;
  ${props => props.width && `width ${props.width}px`};
  font-family: Roboto, sans-serif;
  ::placeholder {
    color: #aaa;
    font-weight: 300;

    ${props =>
      props.hasError &&
      `
      color: ${theme.support.red};
      `}
  }

  &:hover,
  &:focus {
    ${props =>
      props.hasError
        ? `
      border-color: ${theme.support.red};
      `
        : `border-color: ${theme.brand.blue}`};
    background-color: ${props =>
      props.hasError ? '#FEF7F7' : props.isValidated ? '#F6FAF4' : '#fff'};
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }

  ${props =>
    props.isValidated &&
    `
    border-color: ${theme.support.green};
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.5);
    outline: none;
    `}

  ${props =>
    props.hasError &&
    `
      border-color: ${theme.support.red};
      background-color: #fff;
      box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.5);
      outline: none;
      color: ${theme.support.red};
      `}

      // remove X on Edge
      &::-ms-clear {
    display: none;
  }
`

export default Input
