import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Navigation from './Navigation'
import MobileNavigation from './MobileNavigation'

interface NavigationProps {
  menuIsOpen: boolean
  activeLink: string
  toggleMenu: any
}

const NavigationContainer = ({
  menuIsOpen,
  activeLink,
  toggleMenu,
}: NavigationProps) => {
  return (
    <>
      <Navigation activeLink={activeLink} />
      <MobileNavigation menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
    </>
  )
}

export default NavigationContainer
