import StickyButtons from './StickyButtonsContainer'
import React from 'react'

const StickyButtonsDrupal = ({ cookieClosed }) => {
  return (
    <StickyButtons
      btn1Text={'Make a claim'}
      btn2Text={'Request a call back'}
      cookieClosed={cookieClosed}
    />
  )
}

export default StickyButtonsDrupal
