import React from 'react';
import styled from 'styled-components';
import SocialMedia from './SocialMedia';
import theme from '../../styling/theme';

const data = [
  // {
  //   title: 'Terms',
  //   url: '/terms',
  // },
  {
    title: 'Privacy',
    url: '/privacy',
  },
  {
    title: 'IMS Policy',
    url: '/IMS Policy.pdf',
    download: true, // Add a "download" property to specify it as a file download link
  },
  {
    title: 'Sitemap',
    url: '/sitemap',
  },
];

const Disclaimer = () => {
  return (
    <Container>
      <Col>
        <p className="legal-text">&#169; {new Date().getFullYear()} RDA UK Ltd</p>
        {data &&
          data.map((item, key) => {
            return (
              <LinkWrapper key={key}>
                {item.download ? (
                  <StyledLinkDownload href={item.url} download>
                    {item.title}
                  </StyledLinkDownload>
                ) : (
                  <StyledLink href={item.url} key={key}>
                    {item.title}
                  </StyledLink>
                )}
                {key !== data.length - 1 && <Line />}
              </LinkWrapper>
            );
          })}
      </Col>

      <SocialMedia />
    </Container>
  );
};

export default Disclaimer;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 14rem 0 5rem;
  padding-bottom: 10rem;
  @media (max-width: 767px) {
    flex-direction: column;
    margin: 30px 0 5rem;
  }
`;

const Col = styled.div`
  display: flex;
  align-items: center;
  & p {
    margin-right: 10px;
    color: ${theme.text.main};
    font-weight: 700;
    @media (min-width: 360px) {
      margin-right: 3rem;
    }
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLink = styled.a`
  &,
  &:link,
  &:visited {
    font-size: 13px;
    line-height: 23px;
    color: ${theme.text.main};
    position: relative;
    margin-right: 1.5rem;
    overflow: hidden;
  }
  &:hover,
  &:active {
    color: ${theme.brand.blue};
  }
  &::before {
    position: absolute;
    content: ' ';
    height: 2px;
    width: 100%;
    bottom: -1px;
    left: 0;
    background: ${theme.brand.blue};
    margin: 0 auto;
    background: linear-gradient(
      270deg,
      ${theme.brand.blue} 5%,
      rgba(11, 142, 54, 0.8) 10%,
      rgba(11, 142, 54, 0.1) 95%,
      ${theme.brand.blue}
    );
    transform-origin: left;
    transform: translateX(-100%);
    backface-visibility: hidden;
  }
  &:hover::before,
  &:active::before,
  &:focus::before {
    transition: transform 0.6s cubic-bezier(0.67, 0, 0.33, 1);
    transform: translateX(100%);
  }
`;

const StyledLinkDownload = styled.a`
  color: ${theme.brand.blue};

  &:hover,
  &:active {
    color: ${theme.brand.blue};
  }

  // Copy the remaining styles from the StyledLink component or adjust as needed
  &,
  &:link,
  &:visited {
    font-size: 13px;
    line-height: 23px;
    position: relative;
    margin-right: 1.5rem;
    overflow: hidden;
  }
  &::before {
    position: absolute;
    content: ' ';
    height: 2px;
    width: 100%;
    bottom: -1px;
    left: 0;
    background: ${theme.brand.blue};
    margin: 0 auto;
    background: linear-gradient(
      270deg,
      ${theme.brand.blue} 5%,
      rgba(11, 142, 54, 0.8) 10%,
      rgba(11, 142, 54, 0.1) 95%,
      ${theme.brand.blue}
    );
    transform-origin: left;
    transform: translateX(-100%);
    backface-visibility: hidden;
  }
  &:hover::before,
  &:active::before,
  &:focus::before {
    transition: transform 0.6s cubic-bezier(0.67, 0, 0.33, 1);
    transform: translateX(100%);
  }
`;

const Line = styled.div`
  width: 1px;
  height: 10px;
  background: ${theme.grey.keyline};
  margin-right: 1.5rem;
`;
