import React, { useState, useEffect, FunctionComponent } from 'react'
import Input from './Input'
import TextArea from './TextArea'
import Button from '../Button/Button'
import styled from 'styled-components'
import theme from '../../styling/theme'
import RadioBtn from './RadioButton'
import Label from './Label'
import {
  validateOnBlur,
  validatePhoneNumber,
  validateEmail,
  scrollPage,
  getPracticeEmail,
} from '../../../util/functions'
import { setItem, getItem } from '../../../util/functions'
import Dropdown from './CustomDropdown'
import emailjs from 'emailjs-com'
import Loading from './Loading'

interface Option {
  label: string
  value: string
}

enum BusinessSectorsType {
  'none',
  'Aerospace and Defence',
  'Alarms/CCTV',
  'Chemicals, Paints and Adhesive',
  'Construction and Building Material',
  'Electronics and Electrical',
  'Engineering and Machinery Design',
  'Materials, Finishes and Construction',
  'Projects',
  'Sustainability',
  'Pharmaceuticals, Biotechnology and Medical Science',
  'Testing',
}

const businessSectors = [
  'Aerospace and Defence',
  'Alarms/CCTV',
  'Chemicals, Paints and Adhesive',
  'Construction and Building Material',
  'Electronics and Electrical',
  'Engineering and Machinery Design',
  'Materials, Finishes and Construction',
  'Projects',
  'Sustainability',
  'Pharmaceuticals, Biotechnology and Medical Science',
  'Testing',
]

const RequestCallBackForm = ({}) => {
  const [firstName, setFirstName] = useState(getItem('firstName'))
  const [firstNameError, togglefirstNameError] = useState(false)
  const [companyName, setCompanyName] = useState(getItem('companyName'))
  const [companyNameError, togglecompanyNameError] = useState(false)
  const [job, setJob] = useState(getItem('job'))
  const [jobError, toggleJobError] = useState(false)
  const [phone, setPhone] = useState(getItem('phone'))
  const [phoneError, togglePhoneError] = useState(false)

  const [email, setEmail] = useState(getItem('email'))
  const [emailError, toggleEmailError] = useState(false)
  const [anythingElse, setAnythingElse] = useState('')
  const [callBackTimePreference, setCallBackTimePreference] = useState('')
  const [callBackError, toggleCallBackError] = useState(false)

  const [sectorError, toggleSectorError] = useState(false)
  const [isLoading, toggleLoading] = useState(false)
  const [
    businessSector,
    setBusinessSector,
  ] = useState<BusinessSectorsType | null>(BusinessSectorsType.none)
  const [token, setToken] = useState('')

  useEffect(() => {
    if (sectorError && businessSector === BusinessSectorsType.none) {
      toggleSectorError(true)
    } else {
      toggleSectorError(false)
    }
  }, [businessSector])

  useEffect(() => {
    if (token) {
      toggleLoading(true)
      document.querySelector('body').style.overflow = 'hidden'

      if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        let practice = document.querySelector('#general-form-dropdown2')
          .innerText

        setItem('firstName', firstName)
        setItem('companyName', companyName)
        setItem('practice', practice)
        setItem('phone', phone)
        setItem('email', email)

        const params = {
          emailTo: 'chrisddonaldson@hotmail.com',
          message: `
          <p>Form: General Enquiry</p>
          <p>Name: ${firstName}</p>
          <p>Company name: ${companyName}</p>
          <p>Job Title: ${job}</p>
          <p>Phone: ${phone}</p>
          <p>Email: ${email}</p>
          <p>Message: ${anythingElse}</p>
          `,
        }
        console.log('This is a text')
        // emailjs
        //   .send('gmail', process.env.TEMPLATE_ID, params, process.env.USER_ID)
        //   .then(
        //     result => {
        //       console.log(result.text)
        //     },
        //     error => {
        //       console.log(error.text)
        //     }
        //   )
      }
    }
  }, [token])

  useEffect(() => {
    const getParameterByName = (name: string): string => {
      const url = window.location.href
      name = name.replace(/[\[\]]/g, '\\$&')
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
      if (!results) return ''
      if (!results[2]) return ''
      return atob(decodeURIComponent(results[2].replace(/\+/g, ' ')))
    }

    const message = getParameterByName('message')
    if (message) {
      setAnythingElse(message)
    }
  }, [])

  const submitForm = () => {
    let idToScroll: string = ''

    let firstNameError = false
    let companyNameError = false
    let jobError = false
    let phoneError = false
    let emailError = false
    let callBackError = false

    if (firstName.length < 1) {
      firstNameError = true
      togglefirstNameError(true)
      // if (!idToScroll) {
      //   idToScroll = 'firstName'
      // }
    }
    if (businessSector === BusinessSectorsType.none) {
      toggleSectorError(true)
    }

    if (companyName.length < 1) {
      companyNameError = true
      togglecompanyNameError(true)
      // if (!idToScroll) {
      //   idToScroll = 'companyName'
      // }
    }

    if (job.length < 1) {
      jobError = true
      toggleJobError(true)
      // if (!idToScroll) {
      //   idToScroll = 'job'
      // }
    }

    if (!validatePhoneNumber(phone)) {
      phoneError = true
      togglePhoneError(true)
      // if (!idToScroll) {
      //   idToScroll = 'phone'
      // }
    }
    if (!validateEmail(email)) {
      emailError = true
      toggleEmailError(true)
      // if (!idToScroll) {
      //   idToScroll = 'email'
      // }
    }
    if (callBackTimePreference == '') {
      callBackError = true
      toggleCallBackError(true)
      // if (!idToScroll) {
      //   idToScroll = 'email'
      // }
    }

    if (
      !firstNameError &&
      !companyNameError &&
      !jobError &&
      !phoneError &&
      !emailError &&
      !callBackError &&
      !sectorError
    ) {
      let params = {
        firstName,
        companyName,
        job,
        phone,
        email,
        businessSector,
        callBackTimePreference,
        anythingElse,
      }
      // console.log(params)

      emailjs
        .send(
          process.env.SERVICE_ID,
          process.env.CALLBACK_TEMPLATE_ID,
          params,
          process.env.USER_ID
        )
        .then(
          result => {
            console.log(result.text)
            window.location.pathname = '/confirmation'
          },
          error => {
            console.log(error.text)
          }
        )
    } else {
      // if (idToScroll) {
      //   console.log(idToScroll)
      //   scrollPage(idToScroll)
      // }
    }
  }

  return isLoading ? (
    <Loading />
  ) : (
    <Wrapper>
      <Container>
        <Form>
          <Input
            value={firstName}
            onChange={setFirstName}
            label={'Full name*'}
            isValidated={firstName.length > 0}
            hasError={firstNameError}
            errorTitle="Please enter your name"
            onBlur={() =>
              validateOnBlur(firstName.length > 0, togglefirstNameError)
            }
            // id="firstName"
          />

          <Input
            value={companyName}
            onChange={setCompanyName}
            label={'Company name*'}
            isValidated={companyName.length > 0}
            hasError={companyNameError}
            errorTitle="Please enter company name"
            onBlur={() =>
              validateOnBlur(companyName.length > 0, togglecompanyNameError)
            }
            // id="companyName"
          />
          <Input
            value={job}
            onChange={setJob}
            label={'Job title*'}
            isValidated={job.length > 0}
            hasError={jobError}
            errorTitle="Please enter a job title"
            onBlur={() => validateOnBlur(job.length > 0, toggleJobError)}
            // id="job"
          />
          <Input
            value={email}
            onChange={setEmail}
            label={'Email address*'}
            isValidated={validateEmail(email)}
            hasError={emailError}
            errorTitle="Please enter a valid email address"
            onBlur={() => {
              validateOnBlur(validateEmail(email), toggleEmailError)
            }}
            // id="email"
          />
          <Dropdown
            // id="request-a-callback-form-dropdown"
            heading="Business sector*"
            placeholder="Please select"
            options={businessSectors}
            hasError={sectorError}
            errorTitle="Please select a business sector"
            toggleDropdownError={toggleSectorError}
            selectFunction={setBusinessSector}
          />
          <Input
            value={phone}
            onChange={phone => phone.length < 20 && setPhone(phone)}
            label={'Phone number*'}
            isValidated={validatePhoneNumber(phone)}
            hasError={phoneError}
            errorTitle="Please enter a valid telephone number"
            onBlur={() =>
              validateOnBlur(validatePhoneNumber(phone), togglePhoneError)
            }
            // id="phone"
          />

          <RadioContainer>
            <Label label="Please select a prefered time of call back" />
            <BtnsContainer>
              <RadioBtn
                onClick={() => setCallBackTimePreference('AM')}
                isActive={callBackTimePreference === 'AM' ? true : false}
              >
                AM
              </RadioBtn>
              <RadioBtn
                nomarginright
                onClick={() => setCallBackTimePreference('PM')}
                isActive={callBackTimePreference === 'PM' ? true : false}
              >
                PM
              </RadioBtn>
            </BtnsContainer>
          </RadioContainer>
          <TextArea
            heading={'Message'}
            value={anythingElse}
            onChange={setAnythingElse}
          />
          <BtnContainer>
            {/* <Button onClick={submitForm}> */}
            <Button onClick={submitForm}>Submit</Button>
          </BtnContainer>
        </Form>
      </Container>
    </Wrapper>
  )
}

export default RequestCallBackForm

const Wrapper = styled.div``
const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  position: relative;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`
const Text = styled.div`
  width: 38rem;
  margin-right: 8rem;
  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 10rem;
    margin-right: 0;
  }
  @media (max-width: 767px) {
    margin-bottom: 38px;
  }
`

const Heading = styled.h3``
const Subheading = styled.div`
  color: ${theme.text.greyLight};
  font-weight: 300;
  & a,
  & a:link,
  & a:visited {
    color: ${theme.brand.blue};
    font-weight: bold;
  }

  & a:hover,
  & a:active {
    text-decoration: underline;
  }
  @media (min-width: 768px) {
    margin-top: 9px;
  }
`

const Form = styled.div`
  width: 66rem;
  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Flex = styled.div`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
const Gap = styled.div`
  width: 2rem;
  @media (max-width: 767px) {
    display: none;
  }
`
const BtnContainer = styled.div`
  width: 50%;
  margin-top: 4rem;
  display: flex;
  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`
const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  flex: 1;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`

const BtnsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2.8rem;
  @media (min-width: 768px) {
    max-width: 330px;
  }
`
