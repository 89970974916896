import React from 'react'
import styled from 'styled-components'
import theme from '../../styling/theme'

interface RadioBtnProps {
  children: React.ReactNode
  onClick(): void
  isActive: boolean
  small?: boolean
  nomarginright?: boolean
}

interface InputProps {
  isActive: boolean
  small?: boolean
  nomarginright?: boolean
}

const RadioBtn = ({
  children,
  onClick,
  isActive,
  small,
  nomarginright,
}: RadioBtnProps) => {
  return (
    <Input
      onClick={onClick}
      isActive={isActive}
      tabIndex={1}
      small={small}
      tabIndex={0}
      nomarginright={nomarginright}
    >
      {children}
    </Input>
  )
}

export default RadioBtn

const Input = styled.div<InputProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px 0 0;
  margin-right: ${props => props.nomarginright && '0px'};
  border: 1px solid #dadada;

  background-color: white;
  cursor: pointer;
  width: 100%;
  color: ${theme.brand.blue};
  font-size: 14px;
  line-height: 16px;
  outline: none;
  height: 6rem;
  padding: ${props => (props.small ? '1.5rem' : '2.1rem 4rem')};
  min-width: 14rem;
  transition: transform 200ms ease, background-color 200ms ease,
    color 200ms ease, border-color 200ms ease, -webkit-transform 200ms ease;

  & strong,
  & b {
    font-weight: bold;
  }

  ${props =>
    props.isActive &&
    `background-color: ${theme.support.green};
    border: 1px solid ${theme.support.green};
    color: white;
    `};

  &:hover {
    border: 1px solid ${theme.brand.blue};
    background-color: ${theme.brand.blue};
    color: white;
  }

  &:active {
    transform: translate(0px, 3px);
  }

  @media (max-width: 600px) {
    padding: 11px 15px;
    font-size: 12px;
    line-height: 22px;
    margin: 0 8px 0 0;
  }
`
