import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import theme from '../../styling/theme'
import { isTablet, isMobile } from 'react-device-detect'

interface ButtonProps {
  href?: string | null
  onClick?: any
  text: string
  secondary?: boolean
  icon?: any
  target?: boolean
}

const Button = ({
  href = null,
  onClick,
  text,
  secondary,
  icon,
  target,
}: ButtonProps) => {
  const [isActive, toggleActive] = useState(false)
  const [mobileDevice, setMobileDevice] = useState(false)

  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      setMobileDevice(isTablet || isMobile)
    }
    toggleActive(false)
  }, [])

  return (
    <Link
      secondary={secondary}
      data-testid="button-sticky"
      href={href}
      icon={icon}
      mobileDevice={mobileDevice}
      onClick={() => onClick && onClick()}
      target={target && '_blank'}
      onMouseEnter={() => {
        if (!mobileDevice) {
          toggleActive(true)
        }
      }}
      onMouseLeave={() => {
        if (!mobileDevice) {
          toggleActive(false)
        }
      }}
      onTouchStart={() => {
        toggleActive(true)
      }}
      onTouchEnd={() => {
        toggleActive(false)
      }}
      isActive={isActive}
    >
      {text}
    </Link>
  )
}

export default Button

interface LinkProps {
  secondary?: boolean
  isActive: boolean
  href: string
  mobileDevice?: boolean
}

const Link: any = styled.a<LinkProps>`
  text-decoration: none !important;
  cursor: pointer;
  font-size: 1.6rem !important;
  line-height: 2.6rem !important;
  font-family: Roboto;
  font-weight: 400;
  height: 60px;
  width: 20rem;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-weight: 400 !important;
  background: ${props => (props.secondary ? 'white' : theme.brand.blue)};
  border: 1px solid ${theme.brand.blue};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: background 0.3s ease;
  &:hover,
  &:active {
    text-decoration: none;
    border: 1px solid ${theme.brand.hover};
  }
  &,
  &:link,
  &:visited {
    color: ${props => (props.secondary ? theme.brand.blue : 'white')};
  }
  &:hover,
  &:active {
    background: ${theme.brand.hover};
    color: white !important;
  }

  ${props =>
    props.isActive &&
    !props.secondary &&
    `border: 1px solid ${theme.brand.blue};`}

  @media (max-width: 767px) {
    width: 160px;
    max-width: 160px;
    ${props => (props.secondary ? `left: 1px` : 'right: -1px')};
    height: 50px;
  }

  &:hover,
  &:active {
    background: ${props => (props.mobileDevice ? ` ${theme.brand.blue};` : '')};
  }
`
