export const theme = {
  brand: {
    blue: '#0B8E36',
    blue10: 'rgba(11, 142, 54, 0.1)',
    blue20: 'rgba(11, 142, 54, 0.2)',
    red: '#D80054',
    hover: '#006822',
    lightGreen: '#B8F0CB',
  },
  text: {
    main: '#000000',
    second: '#7A7A7A',
    greyLight: '#9D9D9C',
    white: '#ffffff',
  },

  grey: {
    white80: 'rgba(255,255,255,0.8)',
    keyline: '#E6E6E6',
    keylineDark: '#D9D9D9',
    inputHover: '#3D3D3B',
    scroll: '#DEDEDE',
    light: '#ECECEC',
  },
  support: {
    red: '#F56565',
    green: '#4F9F31',
    yellow: '#FFD209',
  },
  background: {
    hpHeader: '#fafafa',
  },
  cursorFollowSize: 40,
}

export default theme
