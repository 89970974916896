import React, { useState } from 'react'
import styled from 'styled-components'
import { formatLink } from '../../../util/functions'
import theme from '../../styling/theme'

interface BurgerMenuLinkProps {
  link: string | null
  text: string
  linkOnClick?: any
}

const BurgerMenuLink = ({ link, text, linkOnClick }: BurgerMenuLinkProps) => {
  const [showDropdown, toggleDropdown] = useState(false)
  return (
    <LinkWrapper
      data-testid="burgermenu-link"
      flip={showDropdown}
      isLink={link}
      onClick={e => {
        if (linkOnClick) {
          return linkOnClick(e)
        }
        if (link) {
          window.location.href =
            (process.env.BASE === '/' ? '' : process.env.BASE) + link
        }
      }}
    >
      {text}
    </LinkWrapper>
  )
}

interface ILinkWrapper {
  flip?: boolean
  isLink?: boolean
}
const LinkWrapper = styled.a<ILinkWrapper>`
  &,
  &:link,
  &:visited {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    outline: none !important;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    color: ${theme.brand.blue} !important;
    font-size: 16px;
    line-height: 26px;
    text-decoration: none !important;
    padding: 20px 25px;
    background-color: ${theme.text.white};
    border-bottom: 1.25px solid ${theme.brand.blue};
    -webkit-text-size-adjust: 100%;
    /* -webkit-transform: translateZ(0px);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000; */
    z-index: 15;
  }

  &:active,
  &:hover {
    color: white !important;
    background-color: ${theme.brand.blue};
  }
  display: flex;
  justify-content: ${props => !props.flip && 'space-between'};
  & p {
    font-size: 18px;
    line-height: 28px;
  }

  &:first-of-type {
    border-top: 1.25px solid ${theme.brand.blue};
  }
`

export default BurgerMenuLink
