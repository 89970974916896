import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, Image, RightCol, AppBar, Toolbar } from './MobileNavigation'
import logo from '../../../images/logo.svg'
import theme from '../../styling/theme'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import BurgerMenuLink from './BurgerMenuLink'

interface BurgerMenuProps {
  links: any
  closeMenu: any
  scrollValue: number
  menuIsOpen: boolean
}

const BurgerMenu = ({
  links,
  closeMenu,
  scrollValue,
  menuIsOpen,
}: BurgerMenuProps) => {
  const [showModal, toggleModal] = useState(false)

  useEffect(() => {
    if (menuIsOpen) {
      const scrollable = document.getElementById('menu-mob-scrollable')
      const body = document.querySelector('body')

      disableBodyScroll(scrollable)

      return () => {
        enableBodyScroll(body)
        clearAllBodyScrollLocks()
        document.documentElement.scrollTop = document.body.scrollTop = scrollValue
      }
    }
  }, [menuIsOpen])

  return (
    <>
      {menuIsOpen && (
        <Container>
          <AppBar>
            <Toolbar transparent>
              <Link href={process.env.BASE}>
                <Image src={logo} alt={'RDA'} style={{ height: '40px', width: 'auto' }} />
              </Link>
              <RightCol>
                <Menu onClick={closeMenu}>
                  <Line first />
                  <Line second />
                  <Line third />
                </Menu>
              </RightCol>
            </Toolbar>
          </AppBar>
          <LinksContainer id="menu-mob-scrollable">
            <LinkContainer>
              {links.map((edge, key) => (
                <BurgerMenuLink link={edge.slug} key={key} text={edge.title} />
              ))}
            </LinkContainer>
          </LinksContainer>
        </Container>
      )}
    </>
  )
}

export default BurgerMenu

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 200vh;
  // bottom: 0;
  z-index: 999;
  background: ${theme.text.white};
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background: white;
`
interface ILine {
  first?: boolean
  second?: boolean
  third?: boolean
  menuIsOpen?: boolean
}
const Line = styled.div<ILine>`
  width: 27px;
  height: 2px;
  margin: ${props => props.second && '5px 0'};
  background-color: ${theme.brand.blue};
  width: ${props => props.menuIsOpen && '15px'};
  transform-style: preserve-3d;
  transition: all 200ms ease 0s;
  opacity: 0;
  will-change: transform;
  backface-visibility: hidden;
`
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 5;
`
