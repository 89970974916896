import React from 'react'
import styled from 'styled-components'
import theme from '../../styling/theme'

interface TextAreaProps {
  heading: string
  value: string
  onChange: any
  color?: string
  minHeight?: string
}

const TextArea = ({
  heading,
  value = '',
  onChange,
  color,
  minHeight,
}: TextAreaProps) => {
  return (
    <Container color={color}>
      <label>
        {heading}
        <Textarea
          minHeight={minHeight}
          data-testid="text-area"
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      </label>
    </Container>
  )
}

export default TextArea

interface IContainer {
  color?: string
}
const Container = styled.div<IContainer>`
  width: 100%;

  & textarea {
    font-family: Roboto, sans-serif;
  }

  & label {
    color: ${props => (props.color ? props.color : '#000000')};
    font-size: 13px;
    line-height: 23px;
    font-weight: 300;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
`
interface ITextarea {
  minHeight?: string
}
const Textarea = styled.textarea<ITextarea>`
  resize: both;
  width: 100%;
  min-height: ${props => (props.minHeight ? props.minHeight : '8rem')};
  border: 1px solid #dddddd;
  box-shadow: inset 0 1.5px 2px 0 rgba(0, 0, 0, 0.25);
  margin-top: 8px;
  padding: 2rem;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  border-radius: 0;
  color: ${theme.text.main};
  &:hover,
  &:focus {
    border: 1px solid ${theme.brand.blue};
    outline: none;
  }
`
