import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Cookie from 'universal-cookie'
import close from '../../../images/small-close.svg'
import ExtraLargeGrid from '../../styling/ExtraLargeGrid'
import color from '../../styling/theme'

const cookies = new Cookie()

interface ICookieBar {
  closeCookies: any
}
const CookieBar = ({ closeCookies }: ICookieBar) => {
  const text = 'This website uses cookies.'
  const [displayCookie, toggleCookie] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const element: HTMLElement | null = document.getElementById(
        'cookie-container'
      )

      if (!cookies.get('cookiesAccepted')) {
        if (element) {
          element.style.animation = 'fadeInUp 2s ease forwards'
          toggleCookie(true)
        }
      }
    }
  }, [])
  const cookiesClosed = () => {
    closeCookies(true)
  }
  const handleCross = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const element: HTMLElement | null = document.getElementById(
        'cookie-container'
      )
      let expiryDate = new Date()
      expiryDate.setYear(expiryDate.getFullYear() + 1)

      cookies.set('cookiesAccepted', 'true', {
        path: '/',
        expires: expiryDate,
      })
      if (element) {
        element.style.animation = 'fadeOutDown 5s ease'
        toggleCookie(false)
      }
    }
  }

  return (
    <Container cookieVisible={displayCookie} id="cookie-container">
      <ExtraLargeGrid>
        <Inner>
          <Text>
            {text}{' '}
            <a
              href={
                (process.env.BASE === '/' ? '' : process.env.BASE) +
                '/cookie-policy'
              }
            >
              Read more
            </a>
          </Text>
          <IconContainer
            onClick={() => {
              handleCross()
              cookiesClosed()
            }}
          >
            <Close
              src={close}
              alt="close"
              onClick={() => {
                handleCross()
                cookiesClosed()
              }}
              data-testid="close-icon"
            />
          </IconContainer>
        </Inner>
      </ExtraLargeGrid>
    </Container>
  )
}

export default CookieBar

interface IContainer {
  cookieVisible: boolean
}
const Container = styled.div<IContainer>`
  background: ${color.brand.blue};
  width: 100%;
  height: 10rem;
  position: fixed;
  left: 0;
  bottom: -100%;
  bottom: ${props => (props.cookieVisible ? '0px' : '-100%')};
  z-index: 500;
  transition: bottom 3s ease-in;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    height: 5rem;
  }
`
const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Text = styled.div`
  color: #fff;
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 300;
  margin: 0;
  width: 70vw;
  text-align: left;
  @media only screen and (min-width: 1024px) {
    font-size: 2rem;
    line-height: 2.4rem;
    width: 784px;
  }

  & a,
  & a:visited,
  & a:hover,
  & a:active {
    cursor: pointer;
    color: white;
    text-decoration: underline;
    font-size: inherit;
  }

  & a:hover {
    opacity: 0.9;
  }
`
const IconContainer = styled.div`
  background: white;
  border-radius: 100px;
  height: 6rem;
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 20;
  &:hover,
  &:active {
    background: rgba(255, 255, 255, 0.8);
  }
  @media only screen and (max-width: 767px) {
    height: 3rem;
    width: 3rem;
  }
`
const Close = styled.img`
  cursor: pointer;
  height: 1.6rem;
  width: 1.6rem;
  @media only screen and (max-width: 767px) {
    height: 1rem;
    width: 1rem;
  }
`
