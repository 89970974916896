export const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const scrollTo = async id => {
  const element = document.getElementById(id)
  if (element && element.scrollIntoView) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

export function throttled(delay: number, fn: any) {
  let lastCall: number = 0
  return function(...args) {
    const now = new Date().getTime()
    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return fn(...args)
  }
}

export const formatLink = (link: string = ''): string | null => {
  if (link) {
    const internalLink = link.split('internal:')

    if (internalLink.length > 1) {
      const prefix: string = process.env.BASE === '/' ? '' : process.env.BASE
      return prefix + internalLink[1]
    } else {
      return link
    }
  }
  return null
}

export const validateOnBlur = (
  isValidated: boolean,
  toggleError: any
): void => {
  if (isValidated) {
    toggleError(false)
  } else {
    toggleError(true)
  }
}

export const validatePhoneNumber = (phone: string | number): boolean => {
  const re = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
  return re.test(phone.toString())
}

export const scrollPage = (id: string) => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
}

export const getPracticeEmail = (practice: string): string => {
  switch (practice) {
    case 'West Wickham':
      return 'westwickham@whiteandcodental.co.uk'
    case 'Battersea Power Station':
      return 'power@whiteandcodental.co.uk'
    default:
      return 'rise@whiteandcodental.co.uk, rosie@whiteandcodental.co.uk'
  }
}

export const getItem = (property: string): string | null => {
  if (typeof window !== 'undefined') {
    return window.sessionStorage.getItem(property) || ''
  }
  return ''
}

export const setItem = (property: string, value: string): void => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(property, value)
  }
}
