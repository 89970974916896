import React from 'react'
import styled from 'styled-components'
import theme from './theme'

interface IMainGrid {
  children: any
  noPaddingMobile?: boolean
  noPaddingTablet?: boolean
  borderTop?: boolean
}
const MainGrid = ({
  children,
  noPaddingMobile,
  noPaddingTablet,
  borderTop,
}: IMainGrid) => {
  return (
    <Container
      noPaddingMobile={noPaddingMobile}
      noPaddingTablet={noPaddingTablet}
      borderTop={borderTop}
    >
      {children}
    </Container>
  )
}
export default MainGrid

const Container = styled.section`
  margin: 0 auto;
  border-top: ${props =>
    props.borderTop && `1px solid ${theme.grey.keylineDark}`};
  width: 100%;
  max-width: 1140px;
  padding: ${props => (props.noPaddingMobile ? '0px' : '0 20px')};

  @media (min-width: 768px) {
    padding: ${props => (props.noPaddingTablet ? '0px' : '0 3%')};
  }

  @media (min-width: 1024px) and (max-width: 1199px) {
    width: 100%;
    padding: 0 3%;
  }

  @media (min-width: 1200px) {
    width: 90%;
    padding: 0;
  }
`
