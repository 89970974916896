import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import facebook from '../../../images/social-media/facebook.svg'
import twitter from '../../../images/social-media/twitter.svg'
import linkedin from '../../../images/social-media/linkedin.svg'
import youtube from '../../../images/social-media/youtube.svg'
import instagram from '../../../images/social-media/instagram.svg'
import theme from '../../styling/theme'

const data = [
  {
    title: 'linkedin',
    url: 'https://www.linkedin.com/company/rdadvisors',
  },
  {
    title: 'instagram',
    url: 'https://www.instagram.com/rndadvisors/',
  },

  {
    title: 'facebook',
    url: 'https://www.facebook.com/RandDAdvisors',
  },
]

const getImageSrc = (title: string) => {
  if (title) {
    let img = ''
    switch (title) {
      case 'linkedin':
        img = linkedin
        break
      case 'twitter':
        img = twitter
        break
      case 'youtube':
        img = youtube
        break
      case 'facebook':
        img = facebook
        break
      case 'instagram':
        img = instagram
        break
      default:
        img = facebook
    }
    return img
  }
}

const SocialMedia = () => {
  return (
    <Container>
      {data &&
        data.map((item, key) => {
          return (
            <SocialMediaLink href={item.url} key={key} target="_blank">
              <img src={getImageSrc(item.title)} />
            </SocialMediaLink>
          )
        })}
    </Container>
  )
}

export default SocialMedia

const Container = styled.div`
  display: flex;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`

const SocialMediaLink = styled.a`
  background: ${theme.brand.blue};
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  & img {
    width: 15px;
    height: 15px;
  }

  &:not(:last-of-type) {
    margin-right: 6px;
  }

  &:hover {
    background: ${theme.text.main};
  }
`
