import React, { useState } from 'react'
import styled from 'styled-components'
import theme from '../../styling/theme'

interface IBtnPrimaryRound {
  children: any
  promptToClick?: boolean
  onClick?: any
  maxWidth?: string
  width?: string
  height?: string
  secondary?: boolean
  tertiary?: boolean
  inactive?: boolean
  href?: string
}
const BtnPrimaryRound = ({
  children,
  promptToClick,
  onClick,
  width,
  maxWidth,
  height,
  secondary,
  tertiary,
  inactive,
  href,
}: IBtnPrimaryRound) => {
  return (
    <Container
      onClick={!inactive ? onClick : null}
      promptToClick={promptToClick}
      width={width}
      height={height}
      secondary={secondary}
      tertiary={tertiary}
      inactive={inactive}
      href={href}
      maxWidth={maxWidth}
    >
      {children}
    </Container>
  )
}

export default BtnPrimaryRound

const Container = styled.a`
  font-family: Roboto;
  background: ${theme.brand.blue};
  width: ${props => (props.width ? props.width : '100%')};
  min-width: 160px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '400px')};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold !important;
  text-align: center;
  color: white;
  cursor: pointer;
  border: 1px solid ${theme.brand.blue};
  transition: background .3s ease;
  
  & span {
    font-weight: 700;
  }

  ${props =>
    props.secondary &&
    `
      color: ${theme.brand.blue};
      background: white;
    `}
    ${props =>
      props.tertiary &&
      `
          color: ${theme.text.white};
          border: 1px solid ${theme.text.white};
          background: transparent;
          font-weight: 400;
        `}
    ${props =>
      props.inactive &&
      `
      background: #d9d9d9;
      border: 1px solid #d9d9d9;
      cursor: initial;
      `}

  &:hover,
  &:hover a {
    background-color: ${theme.brand.hover};
    border-color: ${theme.brand.hover};
    color: ${theme.text.white};
    ${props =>
      props.inactive &&
      `
      background: #d9d9d9;
      border: 1px solid #d9d9d9;
      cursor: initial;
      `}

    ${props =>
      props.secondary &&
      `
        color: white;
        background: ${theme.brand.hover};
      `}

      ${props =>
        props.tertiary &&
        `
            color: ${theme.text.white};
            border: 1px solid ${theme.brand.hover};
            background: ${theme.brand.hover};
          `}
  }

  @media only screen and (max-width: 850px) {
    font-size: 16px;
    height: 50px;
  }
  & b,
  & strong {
    font-weight: 700;
  }

    @media only screen and (max-width: 767px) {
      transform: scaleX(1) scaleY(1);
    }
  }

  ${props =>
    props.promptToClick &&
    `
    @keyframes scaleUpDown {
      0% {
      transform: scaleX(1) scaleY(1);
      }
      50% {
    transform: scaleX(1.03) scaleY(1.03);
      }
      100% {
      transform: scaleX(1) scaleY(1);
      }
    }

    animation-name: scaleUpDown;
    animation-duration: 0.75s;
    animation-iteration-count: infinite;
    `};
`
