import React from 'react'
import styled from 'styled-components'
import theme from './theme'

interface IExtraLargeGrid {
  children: any
  noPaddingMobile?: boolean
  noPaddingTablet?: boolean
  borderTop?: boolean
}
const ExtraLargeGrid = ({
  children,
  noPaddingMobile,
  noPaddingTablet,
  borderTop,
}: IExtraLargeGrid) => {
  return (
    <Container
      noPaddingMobile={noPaddingMobile}
      noPaddingTablet={noPaddingTablet}
      borderTop={borderTop}
    >
      {children}
    </Container>
  )
}
export default ExtraLargeGrid

const Container = styled.section`
  width: 100%;
  max-width: 3000px;
  margin: 0 auto;
  padding: ${props => (props.noPaddingMobile ? '0px' : '0 20px')};
  border-top: ${props =>
    props.borderTop && `1px solid ${theme.grey.keylineDark}`};
  @media screen and (min-width: 767px) and (max-width: 1024) {
    padding: ${props => (props.noPaddingTablet ? '0px' : '0 3%')};
  }
  @media (min-width: 1023px) {
    padding: 0 3%;
  }
  @media screen and (min-width: 1200px) {
    padding: 0 5vw;
  }
`
