import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import StickyBtn from './StickyBtn'
import Cookie from 'universal-cookie'
import { throttled } from '../../../util/functions'
import RequestCallBackModal from '../Modal/RequestCallBackModal'
import MakeClaimModal from '../Modal/MakeClaimModal'
const cookies = new Cookie()

interface IStickyBtnsContainer {
  btn1Text?: string
  btn2Text?: string
  cookieClosed?: boolean
}
const StickyBtnsContainer = ({
  btn1Text,
  btn2Text,
  cookieClosed,
}: IStickyBtnsContainer) => {
  const [showCallBackModal, toggleCallBackModal] = useState(false)
  const [showClaimModal, toggleClaimModal] = useState(false)
  const getCookiePosition = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const container: HTMLElement | null = document.getElementById(
        'btns-sticky-container'
      )

      if (window.pageYOffset > 300) {
        if (container) {
          if (!cookies.get('cookiesAccepted')) {
            if (window.innerWidth <= 767) {
              container.style.cssText =
                'bottom: 5.3rem; opacity: 1; transition: bottom 1s ease-in;'
            } else {
              container.style.cssText =
                'bottom: 12.5rem; opacity: 1; transition: bottom 1s ease-in; '
            }
          } else {
            if (window.innerWidth <= 767) {
              container.style.cssText =
                'bottom: 0px; opacity: 1; transition: bottom 1s ease-in; '
            } else {
              container.style.cssText =
                'bottom: 2.5rem; opacity: 1; transition: bottom 1s ease-in; '
            }
          }
        }
      } else {
        container.style.cssText = 'bottom: -100%;'
      }
    }
  }

  useEffect(() => {
    const eventListener = throttled(100, getCookiePosition)
    window.addEventListener('scroll', eventListener)

    return () => {
      window.removeEventListener('scroll', eventListener)
    }
  }, [])

  useEffect(() => {
    if (cookieClosed) {
      getCookiePosition()
    }
  }, [cookieClosed])

  return (
    <>
      <RequestCallBackModal
        showModal={showCallBackModal}
        toggleModal={() => toggleCallBackModal(false)}
        type={'Request a call back'}
        body={'Inactive account'}
      />
      <MakeClaimModal
        showModal={showClaimModal}
        toggleModal={() => toggleClaimModal(false)}
        type={'Get in touch'}
        body={'Inactive account'}
      />
      <Container id="btns-sticky-container">
        {btn1Text && (
          <StickyBtn onClick={() => toggleClaimModal(true)} text={btn1Text} />
        )}

        {btn2Text && (
          <StickyBtn
            text={btn2Text}
            secondary
            onClick={() => toggleCallBackModal(true)}
          />
        )}
      </Container>
    </>
  )
}

export default StickyBtnsContainer

const Container = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  opacity: 0;
  bottom: -100%;
  right: 5%;
  z-index: 800;
  transition: bottom 0.3s ease;
  @media (max-width: 1199px) {
    right: 3%;
  }
  @media (max-width: 767px) {
    width: 320px;
    right: 1px;
    margin-bottom: 3px;
  }
`
