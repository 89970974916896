import React from 'react'
import styled from 'styled-components'
import theme from '../../styling/theme'

interface LabelProps {
  label: string
}

const Label = ({ label }: LabelProps) => {
  return <Text>{label}</Text>
}

export default Label

const Text = styled.label`
  font-weight: 300;
  font-size: 13px;
  line-height: 23px;
  margin-bottom: 8px;
  color: ${theme.text.main};
  display: block;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 26px;
  }
`
