import React, { useState } from 'react'
import Helmet from 'react-helmet'
import CookieBar from './shared/CookieBar/CookieBar'
import StickyBtnsContainer from './shared/StickyButtons/StickyButtonsDrupal'
import NavigationContainer from './shared/Navigation/NavigationContainer'
import Footer from './shared/Footer/Footer'
import './styling/layout.css'
import TrackVisibility from 'react-on-screen'

interface ITemplateWrapper {
  children: any
  hideStickyButtons?: boolean
  activeLink: string
  title: string
  description: string
}

const TemplateWrapper = ({
  children,
  hideStickyButtons,
  activeLink,
  title,
  description,
}: ITemplateWrapper) => {
  const [menuIsOpen, toggleMenu] = useState(false)
  const [cookiesClosed, setCookiesClosed] = useState(false)

  const closeCookies = () => {
    setCookiesClosed(!cookiesClosed)
  }
const snippet = `<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-74Q1RR16WT"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  // Config for the first GA4 account
  gtag('config', 'G-74Q1RR16WT');

  // Config for the second GA4 account
  gtag('config', 'G-PSTJFQPZ1N');
</script>`


  // Hello
  return (
    <div >
      <Helmet title="RDA">
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta />


      </Helmet>
      <CookieBar closeCookies={closeCookies} />
      <NavigationContainer
        toggleMenu={toggleMenu}
        menuIsOpen={menuIsOpen}
        activeLink={activeLink}
      />
      <div dangerouslySetInnerHTML={{__html: snippet}}/>
      <div >{children}</div>
      <Footer />
      {!hideStickyButtons && (
        <StickyBtnsContainer cookieClosed={cookiesClosed} />
      )}
    </div>
  )
}

export default TemplateWrapper
