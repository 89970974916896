import React from 'react'
import styled from 'styled-components'
import theme from '../../styling/theme'

interface IErrorMessage {
  title: string
  radioError?: boolean
}

interface IError {
  radioError?: boolean
}

const ErrorMessage = ({ title, radioError }: IErrorMessage) => {
  return <ErrorTitle radioError={radioError}>{title}</ErrorTitle>
}

const ErrorTitle = styled.p<IError>`
  color: ${theme.support.red};
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  margin-top: 10px;
  animation-name: shake;
  animation-duration: 1s;
  animation-iteration-count: 1;

  ${props =>
    props.radioError &&
    `
    margin-top: -25px;
    margin-bottom: 35px;
    `};

  @media only screen and (max-width: 850px) {
    line-height: 24px;
    font-size: 14px;
  }
`

export default ErrorMessage
